<template>
  <div class="informationList">
    <el-card style="margin: 16px 22px; border-radius: 12px">
      <div>
        <span class="information-tip">{{ type ? '编辑商讯' : '新增商讯' }}</span>
        <div class="information-line"></div>
      </div>

      <el-form
        :model="paramForm"
        :rules="formRules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item class="title">
          <div class="basicMsg">基本信息</div>
        </el-form-item>
        <el-form-item label="商讯名称" prop="informationName" class="information-name">
          <el-input
            style="width: 450px"
            v-model="paramForm.informationName"
            placeholder="请输入商讯名称"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="封面 " prop="coverImage">
          <div style="color: #7c71e1; font-size: 12px; overflow: hidden">
            <upload-image-one
              :image="paramForm.coverImage"
              :imageShow="paramForm.coverImage"
              :addTitleShow="false"
              @updateImgOne="updateImgOne"
              class="image"
            />
          </div>
        </el-form-item>
        <!-- <el-form-item label="资讯来源" prop="informationResource">
          <el-radio-group v-model="paramForm.informationResource">
            <el-radio label="蓝微专访">蓝微专访</el-radio>
            <el-radio label="蓝微运营">蓝微运营</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item label="资讯类型" prop="informationType">
          <el-radio-group v-model="paramForm.informationType">
            <el-radio label="星名帖">星名帖</el-radio>
            <el-radio label="星聚汇">星聚汇</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item
          v-if="paramForm.informationType === '星名帖'"
          label="关联会员"
          prop="correctionMember"
        >
          <el-select
            v-model="paramForm.correctionMember"
            clearable
            placeholder="请选择"
            @click="handleMember"
          >
            <el-option
              v-for="item in memberList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="标签" prop="tag">
          <el-input
            v-if="inputVisible"
            v-model="paramForm.tag"
            style="width: 126px"
            ref="saveTagInput"
            size="small"
            clearable
            @keyup.enter.native="handleInputConfirm"
          >
          </el-input>
          <div>
            <i class="el-icon-circle-plus-outline" style="color: #7c71e1" />
            <span @click="tagAdd" class="tag-add">添加</span>
          </div>
          <el-tag
            class="tag-style"
            :key="index"
            v-for="(tag, index) in paramForm.tags"
            :disable-transitions="false"
            @close="handleClose(tag)"
            closable
          >
            {{ tag }}
          </el-tag>
        </el-form-item> -->
        <el-form-item label="商讯描述" prop="informationDescribe">
          <div class="editBar">
            <editorbar
              @change="editorChange"
              v-model="paramForm.informationDescribe"
              :isClear="isClear"
            />
          </div>
          <div class="bottom_tip" style="color: #7c71e1; font-size: 12px; width: 868px">
            (图片的建议尺寸长为750px，富文本内图片格式应为jpg、jpeg、png，图片应小于12MB)
          </div>
        </el-form-item>

        <el-form-item label="关键词" class="information-name">
          <el-input
            type="textarea"
            v-model="paramForm.keyword"
            placeholder="请输入内容"
            class="keyword"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="标签" prop="tags" class="information-name">
          <div class="remove_label">
            <div class="lebel_item" v-for="(item, index) in paramForm.tags" :key="index">
              <span>{{ item }}</span>
              <i class="el-icon-close remove_icon" @click="clickRemovLabel(index)"></i>
            </div>
            <div>
              <el-input
                class="add_label"
                v-model="addLebal"
                @keyup.enter.native="clickAddLabel"
              ></el-input>
              <div class="add_box" @click="clickAddLabel">
                <i class="el-icon-circle-plus-outline color add_icon remove_icon"></i>
                <span class="color">添加</span>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item class="title">
          <div class="basicMsg">关联附件</div>
        </el-form-item>
        <el-form-item prop="correctionAccessorys">
          <div
            class="accessory"
            v-for="(item, index) in paramForm.correctionAccessorys"
            :key="index"
          >
            <div class="left">
              <div class="input">{{ item.shortValue }}</div>
              <i
                v-if="item.id === 0"
                class="el-icon-circle-plus-outline addAccessory"
                @click="clickUpload()"
              ></i>
              <i
                v-if="item.id != 0"
                @click="removeAccessory(item.id)"
                class="el-icon-delete delete"
              ></i>
              <el-form-item>
                <UploadFile
                  :lookUrl.sync="item.longValue"
                  :saveUrl.sync="item.longValue"
                  :explainFileList.sync="clientAlias"
                  @upload="uploadAdd"
                  :index="index"
                ></UploadFile>
              </el-form-item>
            </div>
            <div>
              <el-checkbox v-model="item.checkedVip" @change="clickVipRight(item)"
                >仅会员可看</el-checkbox
              >
            </div>
          </div>
        </el-form-item>

        <el-form-item class="title">
          <div class="basicMsg">关联会员</div>
        </el-form-item>

        <el-form-item prop="correctionMembers">
          <div class="contactVip" v-for="(item, index) in paramForm.correctionMembers" :key="index">
            <div class="left">
              <el-select
                @change="selectChange(item, index)"
                v-model="item.contactVipVal"
                filterable
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in ListInformationAndCustomerResultsVal"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <i
                v-if="item.id == 0"
                class="el-icon-circle-plus-outline add"
                @click="clickAddVip(item.id)"
              ></i>
              <i
                v-if="item.id != 0"
                @click="removeContactVip(item.id)"
                class="el-icon-delete delete"
              ></i>
            </div>

            <div class="right">
              <el-checkbox v-model="item.checkedVip" @change="clickLinkVip(item)"
                >仅会员可看</el-checkbox
              >
            </div>
          </div>
        </el-form-item>

        <el-form-item class="title">
          <div class="basicMsg">关联链接</div>
        </el-form-item>

        <el-form-item>
          <div
            class="correlationLinks"
            v-for="(item, index) in paramForm.correctionLinks"
            :key="index"
          >
            <div class="left">
              <div class="linksBox">
                <div class="link">
                  <div class="label">链接</div>
                  <el-input v-model="item.link" placeholder="请输入内容"></el-input>
                </div>

                <div class="linkName">
                  <div class="label">链接名称</div>
                  <el-input v-model="item.linkName" placeholder="请输入内容"></el-input>
                </div>
                <div class="linkDigest">
                  <div class="label">链接摘要</div>
                  <el-input
                    type="textarea"
                    resize="none"
                    v-model="item.linkDigest"
                    placeholder="请输入内容"
                  ></el-input>
                </div>
              </div>
              <i
                v-if="item.id == 0"
                class="el-icon-circle-plus-outline add"
                @click="clickAddLink(item.id)"
              ></i>
              <i v-if="item.id != 0" @click="removeLink(item.id)" class="el-icon-delete delete"></i>
            </div>
            <div class="right">
              <el-checkbox class="lineHeight" v-model="item.checkedVip" @change="clickLinkVip(item)"
                >仅会员可看</el-checkbox
              >
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div class="bottomButton">
        <el-button
          type="primary"
          style="width: 136px; background-color: #7c71e1 !important"
          @click="save"
          >保存</el-button
        >
        <!-- <el-button style="width: 136px; border: 1px solid #7c71e1" @click="cancel" plain
          >取消</el-button
        > -->
        <el-popconfirm
          confirm-button-text="保存"
          cancel-button-text="取消"
          icon="el-icon-info"
          icon-color="red"
          title="您还未保存，是否先保存再退出？"
          :hide-icon="true"
          @confirm="save"
          @cancel="clickCancel"
          class="popconfirm"
        >
          <el-button
            style="width: 136px; border: 1px solid #7c71e1; margin-left: 30px"
            plain
            slot="reference"
            >取消</el-button
          >
        </el-popconfirm>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  detailList,
  getUpdateList,
  getAddList,
  getRelevance,
  selectParticulars,
  listInformationAndCustomerResults
} from '../../../api/information'
import UploadImageOne from '../../../components/UploadImageOne.vue'
import Editorbar from '../../../components/Editorbar'
import UploadFile from '../components/UploadFile'
export default {
  name: 'informationList',
  components: {
    UploadImageOne,
    Editorbar,
    UploadFile
  },
  data() {
    return {
      CheckTrue: true,
      CheckFalse: false,
      paramForm: {
        correctionAccessory: '',
        correctionAccessoryUrl: '',
        correctionAccessorys: [
          {
            checkedVip: false,
            id: 0,
            longValue: '',
            shortValue: ''
          }
        ],
        correctionLink: '',
        correctionLinkDigest: '',
        correctionLinkName: '',
        correctionLinks: [
          {
            checkedVip: false,
            id: 0,
            link: '',
            linkDigest: '',
            linkName: ''
          }
        ],
        correctionMember: '',
        correctionMembers: [
          {
            checkedVip: false,
            contactVipVal: '',
            id: 0
          }
        ],
        coverImage: '',
        createTime: '',
        id: 0,
        informationDescribe: '',
        informationName: '',
        informationResource: '',
        informationType: '',
        keyword: '',
        tag: '',
        tags: [],
        updateTime: ''
      },

      clientAlias1: '',
      clientAlias: [],
      formRules: {
        informationName: [{ required: true, message: '请输入商讯名称', trigger: 'blur' }],
        coverImage: [{ required: true, message: '请上传资讯封面', trigger: 'change' }],
        informationDescribe: [{ required: true, message: '请输入商讯描述', trigger: 'blur' }]
      },
      options: [],
      inputVisible: false,
      dynamicTags: [],
      isClear: false,
      chargeWay: '',
      memberList: {},
      labels: [],
      addLebal: '',
      // 关联附件
      accessory: [
        {
          shortValue: '',
          longValue: '',
          id: 0,
          checkedVip: false
        }
      ],
      // 关联会员
      contactVipList: [
        {
          checkedVip: false, // 关联会员
          contactVipVal: '', // 选中的数据值的id
          // contactVip: [
          //   {
          //     id: '', // id
          //     userName: '' // 值
          //   }
          // ],
          id: 0 // 当前这一项的id
        }
      ],
      // 相关链接
      correlationLinks: [
        {
          checkedVip: false,
          links: {
            link: '', //链接
            linkName: '', //链接名称
            linkDigest: '' //链接摘要
          },
          id: 0
        }
      ],
      // 关联会员数据
      ListInformationAndCustomerResultsVal: []
    }
  },
  filters: {
    valueId(val) {
      // console.log(val)
      return val
    }
  },
  computed: {
    id() {
      // console.log(this.$route.query.id)
      return this.$route.query.id * 1
    },
    type() {
      return this.$route.query.informationType * 1
    }
  },
  async created() {
    this.paramForm.tag = ''
    this.handleMember()
    if (this.type) {
      // console.log(this.id)
      let { data: res } = await detailList({ id: this.id })
      // console.log(res)
      this.paramForm = Object.assign(this.paramForm, res.data)
      if (this.paramForm.correctionMembers) {
        this.paramForm.correctionMembers.map((el) => {
          el.contactVipVal = el.contactVipVal * 1
        })
      }

      if (this.paramForm.correctionAccessorys == null) {
        // console.log(1)
        this.paramForm.correctionAccessorys = [
          {
            checkedVip: false,
            id: 0,
            longValue: '',
            shortValue: ''
          }
        ]
      }

      if (this.paramForm.correctionLinks == null) {
        this.paramForm.correctionLinks = [
          {
            checkedVip: false,
            id: 0,
            link: '',
            linkDigest: '',
            linkName: ''
          }
        ]
      }

      if (this.paramForm.correctionMembers == null) {
        this.paramForm.correctionMembers = [
          {
            checkedVip: false,
            contactVipVal: '',
            // contactVipName: '',
            id: 0
          }
        ]
      }
    }
    this.getListInformationAndCustomerResults()
  },

  methods: {
    save() {
      // console.log(this.paramForm.correctionAccessorys)
      // console.log(this.paramForm)
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.type) {
            // 编辑
            for (let i = 0; i < this.paramForm.correctionAccessorys.length; i++) {
              if (
                this.paramForm.correctionAccessorys[i].shortValue === '' ||
                this.paramForm.correctionAccessorys[i].longValue === ''
              ) {
                this.paramForm.correctionAccessorys.splice(i, 1)
                i--
              }
            }

            for (let i = 0; i < this.paramForm.correctionMembers.length; i++) {
              if (this.paramForm.correctionMembers[i].contactVipVal === '') {
                this.paramForm.correctionMembers.splice(i, 1)
                i--
              }
            }

            for (let i = 0; i < this.paramForm.correctionLinks.length; i++) {
              if (this.paramForm.correctionLinks[i].link === '') {
                this.paramForm.correctionLinks.splice(i, 1)
                i--
              }
            }

            const { data: res } = await getUpdateList(this.paramForm)
            if (res.message !== '成功') {
              return this.$message.warning(res.message)
            }
            if (res.resultCode === 200) {
              this.$message.success('编辑成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('编辑失败，请稍候重试')
            }
          } else {
            //新增
            // console.log(this.paramForm.correctionAccessorys)
            for (let i = 0; i < this.paramForm.correctionAccessorys.length; i++) {
              if (
                this.paramForm.correctionAccessorys[i].shortValue === '' ||
                this.paramForm.correctionAccessorys[i].longValue === ''
              ) {
                this.paramForm.correctionAccessorys.splice(i, 1)
                i--
              }
            }

            for (let i = 0; i < this.paramForm.correctionMembers.length; i++) {
              if (this.paramForm.correctionMembers[i].contactVipVal === '') {
                this.paramForm.correctionMembers.splice(i, 1)
                i--
              }
            }

            for (let i = 0; i < this.paramForm.correctionLinks.length; i++) {
              if (this.paramForm.correctionLinks[i].link === '') {
                this.paramForm.correctionLinks.splice(i, 1)
                i--
              }
            }

            const { data: res } = await getAddList(this.paramForm)
            if (res.message !== '成功') {
              // console.log(this.paramForm.correctionAccessorys, '1111')
              if (this.paramForm.correctionAccessorys.length == 0) {
                this.paramForm.correctionAccessorys = [
                  {
                    checkedVip: false,
                    id: 0,
                    longValue: '',
                    shortValue: ''
                  }
                ]
              }

              if (this.paramForm.correctionLinks.length == 0) {
                this.paramForm.correctionLinks = [
                  {
                    checkedVip: false,
                    id: 0,
                    link: '',
                    linkDigest: '',
                    linkName: ''
                  }
                ]
              }

              if (this.paramForm.correctionMembers.length == 0) {
                this.paramForm.correctionMembers = [
                  {
                    checkedVip: false,
                    contactVipVal: '',
                    // contactVipName: '',
                    id: 0
                  }
                ]
              }
              this.$message.warning(res.message)
              return
            } else if (res.resultCode === 200) {
              this.$message.success('新增成功！')
              this.$router.go(-1)
            } else {
              this.$message.success('新增失败，请稍候重试')
            }
          }
        } else {
          this.$message.warning('请完善基本信息')
        }
      })
    },
    uploadOneImgShow(val) {
      this.paramForm.coverImage = val
    },
    updateImgOne(val) {
      this.paramForm.coverImage = val
    },
    //取消
    cancel() {},

    clickCancel() {
      this.$router.go(-1)
      // console.log('取消')
    },
    //动态添加标签
    tagAdd() {
      this.inputVisible = true
      this.paramForm.tag = ''
    },
    handleInputConfirm(e) {
      // console.log(e)

      let input = this.paramForm.tag
      // console.log(input)
      // console.log(this.paramForm.tags)
      if (input) {
        this.paramForm.tags.push(input)
      }
      this.inputVisible = false
      this.input = ''
    },

    //关联会员方法
    async handleMember() {
      const { data: res } = await getRelevance()
      // console.log(res)
      this.memberList = res.data
    },
    //动态删除标签
    handleClose(tag) {
      this.paramForm.tags.splice(this.paramForm.tags.indexOf(tag), 1)
    },
    // 添加标签
    clickAddLabel() {
      if (this.addLebal.length == 0) {
        return this.$message('请输入要添加的标签内容')
      }
      console.log(this.paramForm.tags)
      if (this.paramForm.tags == null) {
        this.paramForm.tags = []
      }
      this.paramForm.tags.push(this.addLebal)
      this.addLebal = ''
    },
    // 点击移除标签
    clickRemovLabel(idx) {
      this.paramForm.tags.forEach((item, index) => {
        if (index == idx) {
          // console.log(index)
          this.paramForm.tags.splice(index, 1)
        }
      })
      // console.log(this.labels)
      // console.log(id)
    },
    // 富文本输入事件
    async editorChange(val) {
      // console.log(1)
      let replace = val.replace(/<[^>]+>/g, '')
      const { data: res } = await selectParticulars({
        str: replace
      })
      // console.log(res)
      if (res.resultCode == 200 && res.data != null) {
        this.paramForm.keyword = res.data.join(',')
      } else {
        this.paramForm.keyword = ''
      }
    },
    // 点击上传附件的添加按钮
    clickUpload() {
      this.paramForm.correctionAccessorys.push({
        longValue: '',
        shortValue: '',
        id: this.paramForm.correctionAccessorys.length,
        checkedVip: false
      })
      this.paramForm.correctionAccessorys.forEach((item, index) => {
        item.id = index
      })
      // console.log(this.paramForm.correctionAccessorys)
    },
    uploadAdd(val, idx) {
      // console.log(val)
      // console.log(idx)
      const value = val.split('/')
      const value1 = value[value.length - 1].split('F')[0]
      this.paramForm.correctionAccessorys.forEach((item, index) => {
        // console.log(item.id)
        if (item.id == idx) {
          item.shortValue = value1
          item.longValue = val
        }
      })
      this.paramForm.correctionAccessorys.forEach((item, index) => {
        item.id = index
      })
    },
    // 点击仅会员可看
    clickVipRight(item) {
      // console.log(item.checkedVip)
    },
    // 移除附件
    removeAccessory(id) {
      // console.log(id)
      this.paramForm.correctionAccessorys.forEach((item, index) => {
        if (item.id == id) {
          this.paramForm.correctionAccessorys.splice(index, 1)
        }
      })

      this.paramForm.correctionAccessorys.forEach((item, index) => {
        item.id = index
      })
    },
    //  添加关联会员
    clickAddVip(id) {
      const obj = {
        checkedVip: false,
        contactVipVal: '',
        // contactVip: [
        //   {
        //     userName: '',
        //     id: ''
        //   }
        // ],
        id: this.paramForm.correctionMembers.length
      }
      this.paramForm.correctionMembers.push(obj)
      this.getListInformationAndCustomerResults()
    },
    // 移除关联会员
    removeContactVip(id) {
      this.paramForm.correctionMembers.forEach((item, index) => {
        if (item.id == id) {
          this.paramForm.correctionMembers.splice(index, 1)
        }
      })
    },
    //
    clickLinkVip(item) {
      // console.log(item.checkedVip)
    },
    // 添加 会员可查看
    clickAddLink(id) {
      this.paramForm.correctionLinks.push({
        checkedVip: false,
        // links: {
        link: '',
        linkName: '',
        linkDigest: '',
        // },
        id: this.paramForm.correctionLinks.length
      })
    },
    // 移除
    removeLink(id) {
      this.paramForm.correctionLinks.forEach((item, index) => {
        if (item.id == id) {
          this.paramForm.correctionLinks.splice(index, 1)
        }
      })
    },
    // 关联会员数据获取
    async getListInformationAndCustomerResults() {
      const { data: res } = await listInformationAndCustomerResults()
      if (res.resultCode == 200) {
        // this.paramForm.correctionMembers.forEach((item) => {
        //   item.contactVip = res.data
        // })
        this.ListInformationAndCustomerResultsVal = res.data
      }
    },
    // 关联会员选中的值
    selectChange(item, index) {
      // console.log(item.contactVipVal)
    }
  }
}
</script>
<style lang="less" scoped>
.informationList {
  width: 100%;

  .information-tip {
    font-size: 18px;
    color: #968de2;
  }

  .information-line {
    width: 100%;
    height: 0px;
    border: 1px solid #efefef;
    margin-top: 15px;
  }

  .information-name {
    margin-top: 10px;
  }

  .tag-add {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: #7067f2;
  }

  .tag-style {
    background-color: #f1f0fe;
    color: #7067f2;
    border-color: #ffffff;
  }

  /deep/.w-e-text-container {
    z-index: 1 !important;
  }

  /deep/.w-e-menu {
    z-index: 2 !important;
  }

  /deep/.w-e-toolbar {
    z-index: 2 !important;
  }

  /deep/.el-button.is-plain:focus,
  .el-button.is-plain:hover {
    border-color: #7c71e1;
  }

  .editBar {
    width: 800px;
  }

  .title {
    margin-bottom: 0;
    .basicMsg {
      width: 100px;
      // display: flex;
      // justify-content: flex-end;
      font-size: 16px;
      color: #333;
      font-weight: 700;
      margin: 24px 0 0 0;
    }
    /deep/.el-form-item__content {
      margin-left: 35px !important;
      // line-height: 30px !important;
    }
  }

  .accessory {
    margin-top: 15px;
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      .input {
        width: 450px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
        font-size: 14px;
        color: #333333;
        padding-left: 13px;
      }

      .upload {
        width: 72px;
        height: 33px;
        color: #7c71e1;
        background-color: rgba(112, 103, 242, 0.1);
        border-radius: 2px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 25px;
      }
    }
  }

  .contactVip {
    margin-top: 15px;
    width: 1024px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .el-select {
        width: 450px;
        height: 40px;
        border-radius: 4px;
      }
      .add {
        margin-left: 20px;
        font-size: 17px;
        color: #7c71e1;
        cursor: pointer;
      }
    }
  }
}
.correlationLinks {
  margin-top: 15px;
  width: 1024px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .linksBox {
      width: 864px;
      height: 242px;
      border: 1px dashed #dcdcdc;

      .link {
        margin-top: 15px;
        display: flex;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 70px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .el-input {
          width: 450px;
          height: 40px;
        }
      }
      .linkName {
        display: flex;
        margin-top: 15px;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 70px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        .el-input {
          width: 450px;
          height: 40px;
        }
      }
      .linkDigest {
        display: flex;
        margin-top: 15px;
        .label {
          display: flex;
          justify-content: flex-end;
          width: 75px;
          font-size: 12px;
          color: #333333;
          padding-right: 7px;
          box-sizing: border-box;
        }
        /deep/.el-textarea__inner {
          width: 762px;
          height: 85px;
        }
      }
    }

    .add {
      margin-left: 20px;
      font-size: 17px;
      color: #7c71e1;
      cursor: pointer;
    }
  }
  .right {
    line-height: 0px;
    .lineHeight {
      line-height: 0px;
    }
  }
}
.add_label {
  width: 126px;
  height: 40px;
  border-radius: 4px;
}
.add_icon {
  margin: 0 6px 0 14px;
}
.color {
  color: #7067f2;
  cursor: pointer;
}
.add_box {
  display: inline-block;
  cursor: pointer;
}
.addAccessory {
  color: #7067f2;
  cursor: pointer;
  font-size: 17px;
  margin-left: 14px;
}
.lebel_item {
  background-color: rgba(112, 103, 242, 0.1);
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  display: inline-block;
  font-size: 14px;
  color: #7067f2;
  border-radius: 2px;
  margin-right: 9px;
}
.remove_icon {
  cursor: pointer;
}
.remove_label {
  display: flex;
  align-items: center;
}
.keyword {
  width: 800px;
  height: 184px;
}
/deep/.el-textarea__inner {
  width: 868px;
  height: 184px;
}
.delete {
  color: #fd523f;
  font-size: 16px;
  margin-left: 14px;
  cursor: pointer;
}
.bottomButton {
  padding-left: 100px;
}
</style>
